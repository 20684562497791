import React, { useRef, useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { ToggleButtonGroup } from '../../ui-kit/ToggleButtonGroup';
import { ToggleButton } from '../../components/primitives/ToggleButton/ToggleButton';
import { NumericInput } from '../../ui-kit/NumericInput';
import { SxProps } from '@mui/system';
import { useClickOutside } from '../../hooks/useClickOutside';

export interface ISelectSlippageProps {
  defaultOptions: string[];
  value: string;
  onChange: (value: string) => void;
  sx?: SxProps<Theme>;
}

export const SelectSlippage = ({ defaultOptions, value, onChange, sx }: ISelectSlippageProps) => {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleOnChange = (value: string) => {
    value && onChange(value);
    setInputValue('');
  };

  const handleOnBlur = () => {
    if (defaultOptions.includes(inputValue)) {
      setInputValue('');
    }
    inputValue && onChange(inputValue);
  };

  useClickOutside(wrapperRef, () => setOpen(false), !inputValue);

  return (
    <Box
      ref={wrapperRef}
      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...sx }}
    >
      <Typography variant="secondary14" color="text.tertiary">Slippage tolerance</Typography>
      {!isOpen && (
        <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
          {value && (
            <Typography
              variant="secondary14"
              color="text.secondary"
            >
              {value}%
            </Typography>
          )}
          <SettingsRoundedIcon
            onClick={() => setOpen(true)}
            sx={{ width: '18px', height: '18px', color: '#8989A4', cursor: 'pointer' }}
          />
        </Box>
      )}
      {isOpen && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
          <ToggleButtonGroup
            color="primary"
            exclusive={true}
            value={inputValue ? '' : value}
            onChange={(_event, value) => handleOnChange(value)}
            sx={{
              height: '28px',
              flex: { xs: '1 1 auto', md: 'unset' },
              width: { xs: '100%', md: 'unset' },
              mb: { xs: 1, md: 'unset' },
              padding: '2px',
              ['>button']: {
                border: 'none !important',
              },
            }}
          >
            {defaultOptions.map((item) => (
              <ToggleButton
                key={item}
                value={item}
                size="small"
                sx={{ minWidth: '30px' }}
              >
                {item}%
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            height: '28px',
            px: 2,
            borderRadius: '8px',
            border: '1px solid rgba(255, 255, 255, 0.06)',
            backgroundColor: '#2A2A32',
            width: '70px',
          }}>
            <NumericInput
              placeholder="Custom"
              value={inputValue}
              onChange={setInputValue}
              onBlur={handleOnBlur}
              isPercentage={true}
              sx={{ flex: 1, fontSize: '13px' }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
