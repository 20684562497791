import { ToggleButton as MuiToggleButton, ToggleButtonProps, buttonClasses } from '@mui/material';

export const ToggleButton = (props: ToggleButtonProps) => (
  <MuiToggleButton
    {...props}
    sx={{
      display: 'flex',
      gap: 1.5,
      border: 'none',
      borderRadius: '5px !important',
      [`&.${buttonClasses.disabled}`]: {
        border: 'unset !important',
      },
      ...props.sx,
    }}
  />
);
