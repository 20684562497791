import React, { useEffect } from 'react';

export const useClickOutside = (ref: React.RefObject<HTMLDivElement>, action: () => void, enable: boolean, stopPropagation?: boolean) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (enable && ref.current && event.target && !ref.current.contains(event.target as HTMLElement)) {
        stopPropagation && event.stopPropagation();
        action();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [ref, action, enable]);
};
