import BigNumber from 'bignumber.js';
import { USD_DECIMALS } from '@yldr/math-utils';
import { useQuery } from '@tanstack/react-query';
import { ReserveDataHumanized } from '@yldr/contract-helpers';

import { useSharedDependencies } from '../../ui-config/SharedDependenciesProvider';
import { useRootStore } from '../../store/root';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';

export interface IFlashLenderFeeParams {
  debtToken?: ReserveDataHumanized;
  amount?: string;
}

const INITIAL_DATA: number = 0;

export const useFlashLenderFeeQuery = ({ debtToken, amount }: IFlashLenderFeeParams) => {
  const { uiFlashLenderService } = useSharedDependencies();
  const [isMarketsReady, marketData] = useRootStore((store) => [store.isMarketsReady, store.currentMarketData]);

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.FLASH_LENDER_FEE, marketData.chainId, debtToken?.underlyingAsset],
    queryFn: () => uiFlashLenderService.getFlashLenderFee(marketData, debtToken?.underlyingAsset, amount),
    enabled: Boolean(isMarketsReady && debtToken?.underlyingAsset && amount),
    refetchInterval: POLLING_INTERVAL,
    select: (response) => {
      if (debtToken) {
        return BigNumber(response.toString())
          .div(BigNumber(10).pow(debtToken?.decimals))
          .multipliedBy(
            BigNumber(debtToken.priceInMarketReferenceCurrency).div(BigNumber(10).pow(USD_DECIMALS))
          ).toNumber();
      }
      return 0;
    },
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
}

