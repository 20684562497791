import { PopulatedTransaction } from 'ethers';
import { useCallback } from 'react';
import { useRootStore } from '../../store/root';
import { useWeb3Context } from '../../libs/hooks/useWeb3Context';

type Func<T extends any[]> = (...args: T) => PopulatedTransaction;

const firstSuccessfulPromise = <T>(promises: Promise<T>[]): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    let successful = false;
    let rejectedCount = 0;

    if (!promises.length) {
      reject(undefined);
    }

    promises.forEach((promise) => {
      promise.then((result) => {
        if (!successful) {
          successful = true;
          resolve(result);
        }
      }).catch(() => {
        if (!successful) {
          rejectedCount++;
          if (rejectedCount === promises.length) {
            reject();
          }
        }
      });
    });
  });
};

export const useRequestWithFlashloanProvider = () => {
  const [currentMarketData] = useRootStore((state) => [state.currentMarketData]);

  const { provider } = useWeb3Context();

  return useCallback(async <T extends any[]>({ fn, args }: { fn: Func<[string, ...T]>; args: T }) => {
    const fallbackFlashloanProvider = currentMarketData.addresses.FALLBACK_FLASHLOAN_PROVIDER;
    const preferredFlashloanProviders = currentMarketData.addresses.PREFERRED_FLASHLOAN_PROVIDERS;

    if (!provider) return Promise.reject();

    const estimateGasLimit = async (tx: PopulatedTransaction) => {
      const estimatedGas = await provider.estimateGas(tx);
      tx.gasLimit = estimatedGas.mul(115).div(100);
      return tx;
    }

    try {
      const requests = preferredFlashloanProviders.map((flashloanProvider) => {
        const tx = fn(flashloanProvider, ...args);
        return estimateGasLimit(tx);
      });
      const tx = await firstSuccessfulPromise(requests);
      if (tx) return tx;
    } catch {}

    const tx = fn(fallbackFlashloanProvider, ...args);
    return estimateGasLimit(tx);
  }, [provider]);
};
